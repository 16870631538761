<template>
  <app-timeline class="w-100">

    <app-timeline-item v-for="(item, index) in applicationTimeline" :key="'at' + index" :title="item.status_name"
      :subtitle="item.description" :time="getDateTimeFormatted(item.created_at)" x1 />
  </app-timeline>
</template>

<script>
import AppTimeline from "./AppTimeline.vue";
import AppTimelineItem from "./AppTimelineItem.vue";
import helpers from "@/libs/helpers";
import CommonServices from "@/apiServices/CommonServices";
import OperationsServices from "@/apiServices/OperationsServices";
import {
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BFormSelectOption,
  },
  props: {
    applicationTimeline: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getDateTimeFormatted: helpers.getDateTimeFormatted,
  },
};
</script>
<style></style>
